<template>
  <div>
    <div>
      <b-card>
        <div class="d-flex">
          <p class="text-secondary my-auto mr-auto">
            <i :class="$route.meta.iconClass"></i>
            <span style="font-size: 18px" class="font-weight-light">
              Fiche organisation :
            </span>
            <span
              style="font-size: 25px; color: #343a40"
              class="font-weight-bold"
              >{{ selectedData.libelle }}</span
            >
          </p>
          <div class="row mb-3">
            <div class="col-12">
              <modal-actions
                @close="
                  $router.go(
                    $store.getters['routerHistory/fromCreation'] ? -2 : -1
                  )
                "
              />
            </div>
          </div>
        </div>
        <div class="shdow mt-3">
          <div class="row">
            <div class="col-12 d-flex flex-wrap justify-content-between">
              <p
                class="h4 text-secondary my-auto mr-auto"
                style="margin: 6.656px 0 !important"
              >
                Informations générales
              </p>
              <div
                v-if="editMode && (isAdmin || isSuper)"
                class="m-0 d-flex p-0"
              >
                <b-button type="submit" v-if="editMode" @click="updateItem" pill
                  >Confirmer</b-button
                >
                <b-button
                  style="
                    background-color: rgb(173, 181, 189);
                    border-color: rgb(173, 181, 189);
                  "
                  @click="editMode = !editMode"
                  v-if="editMode"
                  pill
                  >Annuler</b-button
                >
                <a
                  v-else
                  href="#"
                  class="text-secondary"
                  style="font-size: 30px"
                  @click.prevent="editMode = !editMode"
                  ><i class="mdi mdi-file-document-edit"></i
                ></a>
              </div>
              <a
                v-else-if="isAdmin || isSuper"
                href="#"
                class="text-secondary"
                style="font-size: 30px; color: #007bffff !important"
                @click.prevent="editMode = !editMode"
                ><i class="mdi mdi-file-document-edit"></i
              ></a>

              <div class="col-12 p-0">
                <hr class="bg-secondary mb-3 mt-1" />
              </div>
            </div>
          </div>

          <div v-if="!editMode" class="row">
            <div class="col-md-6 col-12">
              <div class="my-3">
                <span class="mr-1">Entité juridique : </span>
                <span class="font-weight-bold">{{
                  selectedData.entite ? selectedData.entite.libelle : ""
                }}</span>
              </div>
              <div class="my-3">
                <span class="mr-1">Libellé : </span>
                <span class="font-weight-bold">{{
                  selectedData.libelle ? selectedData.libelle : ""
                }}</span>
              </div>
              <div class="my-3">
                <span class="mr-1">Code organisation : </span>
                <span class="font-weight-bold">{{
                  selectedData.code ? selectedData.code : ""
                }}</span>
              </div>
              <div class="my-3">
                <span class="mr-1">Responsable : </span>
                <span class="font-weight-bold">{{
                  selectedData.responsable
                    ? selectedData.responsable.firstname +
                      " " +
                      selectedData.responsable.lastname
                    : ""
                }}</span>
              </div>
            </div>

            <div class="col-md-6 col-12">
              <div class="my-3">
                <span class="mr-1">Organisation de rattachement : </span>
                <span v-if="selectedData.parent">{{
                  selectedData.parent.libelle
                }}</span>
                <span v-else>-</span>
              </div>
              <div class="my-3">
                <span class="mr-1">Niveau : </span>
                <span class="font-weight-bold">{{
                  selectedData.niveau ? selectedData.niveau : ""
                }}</span>
              </div>
              <div class="my-3">
                <span class="mr-1">Type :</span>
                <span class="font-weight-bold">{{
                  selectedData.type ? selectedData.type : ""
                }}</span>
              </div>
            </div>
          </div>
          <div v-else class="row my-4">
            <div class="col-12 col-md-6">
              <b-form-group
                label-cols="4"
                class="font-weight-bold"
                label="Entité juridique :"
              >
                <div class="font-weight-normal">
                  <v-select
                    label="text"
                    :reduce="({ value }) => value"
                    v-model="newOrganisation.Entite"
                    :options="entitesList"
                    style="padding-right: 0"
                    :class="{
                      'is-invalid form-control p-0':
                        $v.newOrganisation.Entite.$error &&
                        $v.newOrganisation.Entite.$dirty,
                    }"
                    @input="newOrganisation.parent = null"
                  >
                    ></v-select
                  >

                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newOrganisation.Entite.$dirty"
                  >
                    {{
                      !$v.newOrganisation.Entite.required
                        ? "Champ obligatoire"
                        : ""
                    }}
                  </span>
                </div>
              </b-form-group>
              <b-form-group
                class="font-weight-bold"
                label-cols="4"
                label="Libellé :"
              >
                <b-form-input
                  placeholder="Libellé"
                  v-model="newOrganisation.libelle"
                  type="text"
                  :class="{
                    'is-invalid':
                      $v.newOrganisation.libelle.$error &&
                      $v.newOrganisation.libelle.$dirty,
                  }"
                ></b-form-input>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newOrganisation.libelle.$dirty"
                >
                  {{
                    !$v.newOrganisation.libelle.required
                      ? "Champ obligatoire"
                      : !$v.newOrganisation.libelle.minLength
                      ? `Le champ doit contenir au moins ${$v.newOrganisation.libelle.$params.minLength.min} caractères.`
                      : ""
                  }}
                </span>
              </b-form-group>
              <b-form-group
                label-cols="4"
                label="Code organisation :"
                class="font-weight-bold"
              >
                <b-form-input
                  placeholder="Code organisation"
                  v-model="newOrganisation.code"
                  type="text"
                  :class="{
                    'is-invalid':
                      $v.newOrganisation.code.$error &&
                      $v.newOrganisation.code.$dirty,
                  }"
                ></b-form-input>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newOrganisation.code.$dirty"
                >
                  {{
                    !$v.newOrganisation.code.required ? "Champ obligatoire" : ""
                  }}
                </span>
              </b-form-group>
              <b-form-group
                label-cols="4"
                label="Responsable :"
                class="font-weight-bold"
              >
                <div class="font-weight-normal">
                  <v-select
                    label="text"
                    :reduce="(user) => user.value"
                    :options="responsables"
                    placeholder="Responsable"
                    v-model="newOrganisation.responsable"
                    type="text"
                    style="padding-right: 0"
                    :class="{
                      'is-invalid form-control p-0':
                        $v.newOrganisation.responsable.$error &&
                        $v.newOrganisation.responsable.$dirty,
                    }"
                  >
                    <template #no-options> Liste vide </template>
                  </v-select>
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newOrganisation.responsable.$dirty"
                  >
                    {{
                      !$v.newOrganisation.responsable.required
                        ? "Champ obligatoire"
                        : ""
                    }}
                  </span>
                </div>
              </b-form-group>
            </div>
            <div class="col-12 col-md-6">
              <b-form-group
                label-cols="4"
                class="font-weight-bold"
                label="Organisation de rattachement :"
              >
                <div class="font-weight-normal">
                  <v-select
                    label="text"
                    :reduce="({ value }) => value"
                    v-model="newOrganisation.parent"
                    :options="organisationsList"
                    placeholder="Organisation de rattachement"
                    @input="
                      () => {
                        if (newOrganisation.parent) {
                          this.newOrganisation.niveau =
                            this.ORGANISATIONS.find(
                              (orga) => orga['@id'] === newOrganisation.parent
                            ).niveau + 1;
                        } else {
                          this.newOrganisation.niveau = 1;
                        }
                      }
                    "
                  ></v-select>
                </div>
              </b-form-group>
              <b-form-group
                class="font-weight-bold"
                label-cols="4"
                label="Niveau :"
              >
                <b-form-input
                  placeholder="Niveau"
                  v-model="newOrganisation.niveau"
                  type="number"
                  disabled
                  :class="{
                    'is-invalid':
                      $v.newOrganisation.niveau.$error &&
                      $v.newOrganisation.niveau.$dirty,
                  }"
                ></b-form-input>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newOrganisation.niveau.$dirty"
                >
                  {{
                    !$v.newOrganisation.niveau.required
                      ? "Champ obligatoire"
                      : ""
                  }}
                </span>
              </b-form-group>
              <b-form-group
                class="font-weight-bold"
                label-cols="4"
                label="Type :"
              >
                <div class="font-weight-normal">
                  <v-select
                    :options="type"
                    class="font-weight-normal"
                    v-model="newOrganisation.type"
                    style="padding-right: 0"
                    :class="{
                      'is-invalid form-control p-0':
                        $v.newOrganisation.type.$error &&
                        $v.newOrganisation.type.$dirty,
                    }"
                  >
                    <template #no-options> Liste vide </template>
                  </v-select>
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newOrganisation.type.$dirty"
                  >
                    {{
                      !$v.newOrganisation.type.required
                        ? "Champ obligatoire"
                        : ""
                    }}
                  </span>
                </div>
              </b-form-group>
            </div>
          </div>
        </div>
        <br />
        <hr />
        <div class="row" style="margin-top: -15px">
          <div class="col-12 px-0 p-4">
            <b-tabs
              :value="0"
              @changed="activeTab = $store.state.tabs.activeTab"
              @activate-tab="
                (newTabIndex) => $store.dispatch('tabs/changeTab', newTabIndex)
              "
              class="tab-solid tab-solid-primary mt-3"
            >
              <b-tab title="Équipes">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="d-flex my-2">
                      <div>
                        <span class="h4 text-secondary mr-auto my-auto"
                          >Liste des équipes</span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="
                      col-12 col-md-6
                      d-flex
                      justify-content-end
                      align-btn-table-2
                    "
                  >
                    <button
                      type="button"
                      class="
                        btn
                        h1
                        mb-2
                        test-btn
                        btn-secondary
                        d-flex
                        align-items-center
                      "
                      @click="addItem"
                      :disabled="
                        !(isAdmin || isSuper) || !disponibleResponsables
                      "
                      style="
                        background-color: #007bffff !important;
                        border-color: #007bffff !important;
                      "
                    >
                      <i class="mdi mdi-plus mr-2"></i>
                      Créer une équipe
                    </button>
                  </div>
                  <div class="col-12">
                    <hr class="mt-1 mb-3 bg-secondary" />
                  </div>
                  <div class="col-12">
                    <TeamTable
                      :items="ACTEURS.teams"
                      :laoding="loading"
                      :name="selectedData.libelle"
                      :id="selectedData['@id']"
                      @deleteItem="deleteTeam"
                      @reload="reload"
                    ></TeamTable>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Collaborateurs">
                <div class="row">
                  <div class="col-12 col-sm-3 my-3">
                    <span class="h4 text-secondary mr-auto my-auto"
                      >Liste des collaborateurs</span
                    >
                  </div>
                  <div class="col-12">
                    <hr class="mt-1 mb-3 bg-secondary" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div>
                      <b-table
                        responsive
                        :items="members"
                        :fields="tableFields"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        show-empty
                        :busy="loading"
                        bordered
                        striped
                      >
                        <template #empty>
                          <div class="text-center text-gray">Table vide</div>
                        </template>
                        <template #table-busy>
                          <div class="text-center text-primary my-2">
                            <b-spinner class="align-middle mr-2"></b-spinner>
                            <strong>Chargement...</strong>
                          </div>
                        </template>
                        <template v-slot:cell(function)="data">
                          {{ data.value ? data.value : "-" }}
                        </template>
                        <template v-slot:cell(hiearchie)="data">
                          {{ data.value ? data.value : "-" }}
                        </template>
                        <template v-slot:cell(responsable)="data">
                          <table-responsable
                            :responsable="data.item"
                          ></table-responsable>
                        </template>
                      </b-table>
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        aria-controls="table-list"
                        align="right"
                      >
                      </b-pagination>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";

import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import TeamTable from "../components/TeamTable";
import { minLength, required } from "vuelidate/lib/validators";
import { concat } from "lodash";
import TableResponsable from "@/components/custom/TableResponsable";
import { ENTITE_URL, USER_URL } from "@/helpers/constURL";

export default {
  components: {
    TableResponsable,
    ModalActions,
    TeamTable,
  },
  data: () => ({
    editMode: false,
    selectedCountries: [],
    sortBy: "name",
    perPage: 10,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    loading: true,
    members: null,
    activeTab: null,
    selectedData: {},
    years: null,
    months: null,
    day: null,
    tableFields: [
      { key: "responsable", label: "Responsable", sortable: true },
      { key: "function", label: "Fonction", sortable: true },
      { key: "hiearchie", label: "Niveau hiércahique ", sortable: true },
    ],
    newOrganisation: {
      Entite: {
        libelle: "",
      },
      libelle: "",
      code: "",
      sousOrganisations: [],
      niveau: 0,
      parent: null,
      type: [],
      responsable: null,
    },
    selectedResponsable: null,
  }),
  methods: {
    concat,
    addItem() {
      if (this.isAdmin || this.isSuper) {
        var data = this.ACTEURS.teams.some((contribute) => contribute.editMode);
        if (data == false) {
          this.ACTEURS.teams.unshift({ editMode: true });
        }
      }
    },
    reload() {
      this.$store.dispatch("organisation/fetchActeur", this.$route.params.id);
      // this.$store.dispatch("organisation/fetchOrganisation", 1);
    },
    deleteTeam: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          if (this.$store.dispatch("teams/deleteTeam", id)) {
            this.ACTEURS.teams = this.ACTEURS.teams.filter(
              (item) => item.id !== id
            );
            this.$store.dispatch("teams/fetchAllTeams");

            Swal.fire("Supprimé!", "", "success");
          }
        }
      });
    },
    updateItem() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var item = {
          id: this.$route.params.id,
          type: this.newOrganisation.type,

          // sousOrganisations: this.newOrganisation.sousOrganisations.map(
          //   (cov) => cov.value
          // ),

          parent: this.newOrganisation.parent,
          code: this.newOrganisation.code,
          libelle: this.newOrganisation.libelle,
          entite: `/api/${ENTITE_URL}/${this.newOrganisation.Entite}`,
          niveau: parseInt(this.newOrganisation.niveau),
          responsable: `api/users/${this.newOrganisation.responsable}`,
        };
        this.editMode = !this.editMode;
        this.$store
          .dispatch("organisation/updateOrganisation", item)
          .then(() => {
            Swal.fire("L'organisation est mise à jour !", "", "success");
            this.$store.dispatch(
              "organisation/fetchOrganisation",
              this.$route.params.id
            );
          });
      }
    },
  },

  validations: {
    newOrganisation: {
      Entite: {
        required,
      },
      libelle: {
        required,
        minLength: minLength(5),
      },
      type: {
        required,
      },
      niveau: {
        required,
      },
      code: {
        required,
      },
      responsable: {
        required,
      },
    },
  },
  mounted() {},
  watch: {
    ORGANISATION() {
      this.selectedData = this.ORGANISATION;
      this.loading = false;
      this.newOrganisation = {
        Entite: this.ORGANISATION.entite ? this.ORGANISATION.entite.id : "",
        libelle: this.ORGANISATION.libelle ? this.ORGANISATION.libelle : "",
        code: this.ORGANISATION.code ? this.ORGANISATION.code : "",
        sousOrganisations: this.ORGANISATION.sousOrganisations
          ? this.ORGANISATION.sousOrganisations.map((cov) => ({
              value: cov["@id"],
              text: cov.libelle,
            }))
          : "",
        type: this.ORGANISATION.type ? this.ORGANISATION.type : "",
        niveau: this.ORGANISATION.niveau ? this.ORGANISATION.niveau : "",
        responsable: this.ORGANISATION.responsable
          ? this.ORGANISATION.responsable.id
          : "",
        parent: this.ORGANISATION.parent
          ? this.ORGANISATION.parent["@id"]
          : null,
      };
    },
    ACTEURS() {
      this.members = this.ACTEURS.teams.map((data) => ({
        members: concat(data.members.map((item) => item)),
      }));
      var con = [];
      for (var i = 0; i < this.members.length; i++) {
        con = concat(con, this.members[i].members);
      }
      this.members = con;
    },
  },

  created() {
    this.$store
      .dispatch("organisation/fetchOrganisation", this.$route.params.id)
      .then(() => {
        this.$store.dispatch("loader/toggleLoading", false);
      });
    this.$store.dispatch("organisation/fetchActeur", this.$route.params.id),
      this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("type/fetchOrganisationTypes");
    this.$store.dispatch("entity/fetchAllEntities");
    this.$store.dispatch("users/fetchAllResponsables");
  },

  computed: {
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("organisation", ["ORGANISATION"]),
    ...mapGetters("type", ["ORG_TYPES"]),
    ...mapGetters("organisation", ["ORGANISATIONS"]),
    ...mapGetters("entity", ["ENTITIES"]),
    ...mapGetters("organisation", ["ACTEURS"]),
    ...mapGetters(["isAdmin", "isSuper"]),
    ...mapGetters("teams", ["TEAMS"]),

    rows() {
      return this.members ? this.members.length : 0;
    },
    disponibleResponsables() {
      return this.RESPONSABLES.map((responsable) => ({
        value: `/api/${USER_URL}/${responsable.id}`,
      })).filter(
        (respo) =>
          !this.TEAMS.find((item) => item.manager["@id"] === respo.value)
      ).length
        ? true
        : false;
    },
    responsables() {
      return this.RESPONSABLES.map((responsable) => ({
        value: responsable.id,
        text: responsable.firstname + " " + responsable.lastname,
      }));
    },
    organisationsList() {
      return this.ORGANISATIONS.filter(
        (data) => data.id !== parseInt(this.$route.params.id)
      )
        .filter((organisation) =>
          this.newOrganisation.Entite && organisation.entite
            ? parseInt(organisation.entite.id) ===
              parseInt(this.newOrganisation.Entite)
            : false
        )
        .filter((organisation) => organisation.niveau === 1)
        .map((org) => ({
          value: org["@id"],
          text: org.libelle,
        }));
    },
    type() {
      return this.ORG_TYPES;
    },
    entitesList() {
      return this.ENTITIES.map((entity) => ({
        value: entity.id,
        text: entity.libelle,
      }));
    },
  },
};
</script>

<style></style>
